.content {
  text-align: center;

  background-color: #ffffff;
  background-color: #f7fcfc;
  color: #646e72;

  padding: 40px;
  margin: auto;

  height: 2681px;
  min-height: auto;
  min-width: auto;
}

.list-header {
  display: flex;
  margin-left: 8px;
}
