.list-header {
  display: flex;
  justify-content: space-between;
}

.icon-group {
  display: flex;
}

.icon {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 8px;
}

.divider {
  margin-top: 5px;
}
