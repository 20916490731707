.detail-column {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: flex-start;
  margin-left: ;
}

.icon-text {
  color: #646e73;
  font-size: 12px;
}

.icon-detail {
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  text-align: start;
  letter-spacing: normal;
  color: #646e73;
  color: #2e3c42;
}
