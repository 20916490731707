.App-header {
  background-color: #0e1e25;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #7e878b;

  padding: 20px 40px 20px 40px;
  z-index: 999;
  box-sizing: border-box;
}

/* * */

.header-container {
  width: 90%;
  max-width: 945px;

  display: flex;
  justify-content: space-between;
}

.header-logo-title {
  display: flex;
}

.header-logo {
  width: 40px;
}

.header-title {
  margin-left: 10px;

  font-size: 1.5rem;
  color: #ffffff;
}

.header-joinButton {
  background-color: var(--success-color);

  font-weight: 500;

  border-radius: 8px;
  margin-right: 0px;
  width: 96px;
  padding: 8px 16px 8px 16px;
  min-width: auto;

  color: #ffffff;
}

/* * */

.header-tab-container {
  /* background-color: springgreen; */

  width: 90%;
  max-width: 945px;

  margin-top: 30px;

  display: flex;
}

.header-tab {
  /* background-color: steelblue; */
  background-color: #0e1e25;

  color: #7e878b;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  letter-spacing: normal;
  font-weight: 500;

  margin: 4px;
  padding: 12px;

  width: 96px;

  border-radius: 8px;
}

.hov:hover {
  background-color: #7e878b;
  color: #ffffff;
}

.active {
  color: #ffffff;
  font-weight: 700;
}
