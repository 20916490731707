.list-container {
  background-color: var(--light-color);
  background-color: #ffffff;
  height: 60px;
  padding: 10px;

  /* display: flex; */
  /* justify-content: space-between; */

  display: grid;
  grid-template-columns: 5% 45% 15% 10% 2% 1%;
  grid-template-columns: 1fr minmax(300px, 4fr) 1fr 1fr 0.2fr 1fr;
  gap: 15px;
  align-items: center;

  border-radius: 4px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px;
}

@media (max-width: 900px) {
  .list-container {
    background-color: orange;
    grid-template-columns: 1fr minmax(300px, 4fr) 1fr 1fr 0.5fr;
    grid-auto-flow: dense;
  }
}

@media (max-width: 500px) {
  .list-container {
    background-color: purple;
    grid-template-columns: 1fr 8fr 1fr;
  }
}
