.layout-grid {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 1fr;
}

.layout-grid-hero {
  grid-template-columns: 60% 1fr;
}

.card {
  background-color: #ffffff;
  background-color: tomato;
  border-radius: 8px;
  box-shadow: 0 2px 4px #0e1e251f;
  color: #646e73;
  display: flex;
  margin-top: 24px;
  min-width: 264px;
  max-width: 680px;
  padding: 24px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-size-adjust: 100%;

  color: rgb(100, 110, 115);
  background-color: rgb(255, 255, 255);
  border-color: rgb(164, 170, 173);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px;

  margin-left: 45px;
}

.media {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tw-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.heading {
  font-size: 22px;
  font-weight: 600;
  line-height: 27.5px;
  text-size-adjust: 100%;

  color: rgb(46, 60, 66);
}

.link {
  display: block;
  margin-top: 8px;
}

.status {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  margin-left: 0px;
  color: rgb(21, 132, 123);
}

.sucess {
  color: rgb(100, 110, 115);
  border-color: rgb(164, 170, 173);

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.bttn-container {
  padding: 24px;
}

.button {
  font-size: 16px;
  font-weight: 500;
  color: rgb(14, 30, 37);
}
