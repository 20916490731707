.App-footer {
  background-color: #0e1e25;
  color: #7e878b;

  height: 120px;
  padding: 20px 40px;

  min-height: auto;
  min-width: auto;
}
